
import { defineComponent, ref, watch } from "vue";
import ExhibitionsTop from "../shared/ExhibitionsTop.vue";
import ExhibitionsList from "../shared/ExhibitionsList.vue";
import SpinnerContainer from "../../../shared/components/SpinnerContainer.vue";
import { ExhibitionDto, useDocumentTitle, useExhibitionsResource } from "@kunsten/core";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "Exhibitions",
    components: { ExhibitionsTop, ExhibitionsList, SpinnerContainer },
    setup() {
        const { t } = useI18n();

        useDocumentTitle(t("general.base-title"), t("navigation.exhibitions"));

        const selectedYear = ref(new Date().getFullYear().toString());
        const filteredEntities = ref([] as ExhibitionDto[]);
        const { loading, entities } = useExhibitionsResource({
            page: "1",
            pagination: "false",
            "order[startDate]": "desc",
        });

        watch([selectedYear, entities], () => {
            filteredEntities.value = entities.value.filter((entity) => {
                const startYear = new Date(entity.startDate).getFullYear();
                const endYear = new Date(entity.endDate).getFullYear();
                if (Number(selectedYear.value) >= startYear && Number(selectedYear.value) <= endYear) {
                    return true;
                }
                return false;
            });
        });

        return { loading, entities, selectedYear, filteredEntities };
    },
});
