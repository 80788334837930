import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38179ea3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "exhibitions-top" }
const _hoisted_2 = { class: "exhibitions-top-select mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_EntityListTop = _resolveComponent("EntityListTop")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_EntityListTop, null, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("exhibition.header")), 1)
      ]),
      "first-paragraph": _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("exhibition.first-paragraph")), 1)
      ]),
      "second-paragraph": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_MultiSelect, {
            modelValue: _ctx.selectedYear,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedYear) = $event)),
            options: _ctx.getOptions(),
            search: false,
            single: true,
            clearBtn: false,
            placeholder: _ctx.$t('exhibition.select-year'),
            sort: "desc"
          }, null, 8, ["modelValue", "options", "placeholder"])
        ])
      ]),
      _: 1
    })
  ]))
}