
import { defineComponent, PropType, ref } from "vue";
import EntityListTop from "../../entity/EntityListTop.vue";
import MultiSelect from "../../../shared/components/MultiSelect.vue";
import { ExhibitionDto } from "@kunsten/core";

export default defineComponent({
    name: "ExhibitionsTop",
    components: { EntityListTop, MultiSelect },
    props: {
        year: {
            type: Number,
            required: true,
        },
        entities: {
            type: Array as PropType<ExhibitionDto[]>,
            default: () => [],
        },
    },
    data() {
        return {
            selectedYear: [this.year],
        };
    },
    setup(props) {
        const years = ref([
            ...new Set(props.entities.filter((e) => e.startDate).map((e) => new Date(e.startDate).getFullYear())),
        ]);

        const maxYear = ref(props.year);
        const minYear = ref(years.value[years.value.length - 1]);

        const getOptions = () => {
            const options = [{ value: maxYear.value.toString(), text: maxYear.value.toString() }];

            let year = maxYear.value;

            while (year !== minYear.value) {
                year--;
                options.push({
                    value: year.toString(),
                    text: year.toString(),
                });
            }

            return options;
        };

        return { getOptions };
    },
    watch: {
        selectedYear(value) {
            this.$emit("update:year", value[0]);
        },
    },
});
