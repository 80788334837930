import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-586ae8ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "entity-top page-container py-6",
  "aria-labelledby": "entity-list-top-heading"
}
const _hoisted_2 = { id: "entity-list-top-heading" }
const _hoisted_3 = { class: "entity-top-paragraphs" }
const _hoisted_4 = { class: "mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_Label, { size: "bg" }, {
      title: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _renderSlot(_ctx.$slots, "header", {}, undefined, true)
        ])
      ]),
      sublabel: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", null, [
            _renderSlot(_ctx.$slots, "first-paragraph", {}, undefined, true)
          ]),
          _createElementVNode("p", _hoisted_4, [
            _renderSlot(_ctx.$slots, "second-paragraph", {}, undefined, true)
          ])
        ])
      ]),
      _: 3
    })
  ]))
}