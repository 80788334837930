
import { ExhibitionDto } from "@kunsten/core";
import { defineComponent, PropType } from "vue";
import Label from "../../../shared/components/Label.vue";
import EsDate from "../../../shared/components/EsDate.vue";
import TranslationAlt from "../../../shared/components/TranslationAlt.vue";

export default defineComponent({
    name: "ExhibitionsList",
    components: { Label, TranslationAlt, EsDate },
    props: {
        entities: {
            type: Array as PropType<ExhibitionDto[]>,
            defualt: () => [],
        },
    },
    setup() {
        const entityPath = (entity: ExhibitionDto) => {
            // slicing /api/entities/123 to /entities/123
            return entity["@id"].slice(4, entity["@id"].length);
        };

        return { entityPath };
    },
});
