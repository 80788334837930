import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50ff2870"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "exhibitions-page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExhibitionsTop = _resolveComponent("ExhibitionsTop")!
  const _component_ExhibitionsList = _resolveComponent("ExhibitionsList")!
  const _component_SpinnerContainer = _resolveComponent("SpinnerContainer")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ExhibitionsTop, {
          year: _ctx.selectedYear,
          "onUpdate:year": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedYear) = $event)),
          entities: _ctx.entities
        }, null, 8, ["year", "entities"]),
        _createVNode(_component_ExhibitionsList, { entities: _ctx.filteredEntities }, null, 8, ["entities"])
      ]))
    : (_openBlock(), _createBlock(_component_SpinnerContainer, { key: 1 }))
}