import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0bf1655d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "exhibitions-list" }
const _hoisted_2 = { class: "page-container pt-6 pb-5" }
const _hoisted_3 = {
  key: 0,
  class: "exhibitions-list-empty mb-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslationAlt = _resolveComponent("TranslationAlt")!
  const _component_EsDate = _resolveComponent("EsDate")!
  const _component_Label = _resolveComponent("Label")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entities, (entity) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "exhibitions-list-item mb-5",
          key: entity['@id']
        }, [
          _createVNode(_component_router_link, {
            to: { path: _ctx.entityPath(entity) }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Label, {
                light: true,
                size: "lg"
              }, {
                title: _withCtx(() => [
                  _createElementVNode("h2", null, [
                    _createVNode(_component_TranslationAlt, {
                      data: entity,
                      property: "title"
                    }, null, 8, ["data"])
                  ])
                ]),
                subtitle: _withCtx(() => [
                  _createElementVNode("div", null, _toDisplayString(entity.venue), 1),
                  _createVNode(_component_EsDate, {
                    format: "slash",
                    startDate: entity.startDate,
                    endDate: entity.endDate,
                    period: true
                  }, null, 8, ["startDate", "endDate"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128)),
      (!_ctx.entities.length)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t("exhibition.no-exhibitions")), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}